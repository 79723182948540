export default {
  path: '',
  component: () => import(/* webpackChunkName: "LayoutPortal" */ '@portal/layouts/LayoutPortal.vue'),
  children: [
    {
      path: '',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@portal/pages/home/HomePage.vue'),
    },
    {
      path: '/sobre-mi',
      name: 'about',
      component: () => import(/* webpackChunkName: "sobrem-mi" */ '@portal/pages/about/AboutPage.vue'),
    },
    {
      path: '/trabajos',
      name: 'works',
      component: () => import(/* webpackChunkName: "trabajos" */ '@portal/pages/works/WorksPage.vue'),
    },
    {
      path: '/trabajos/:id',
      name: 'work-id',
      component: () => import(/* webpackChunkName: "trabajos-id" */ '@portal/pages/works/Work[id]Page.vue'),
      props: (route: any) => {
        return {
          id: route.params.id+''
        };
      }
    },
    {
      path: '/certificados',
      name: 'certified',
      component: () => import(/* webpackChunkName: "trabajos" */ '@portal/pages/certified/CertifiedPage.vue'),
    },
  ]
};
