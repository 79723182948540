export const worksData = {
  "svr-seguro-vehicular": {
    "id": "svr-seguro-vehicular",
    "work_name": "Seguro Vehicular Tracking",
    "description": "<p>Proyecto realizado para demostrar parte de habilidades en react.js, el proyecto basado en un Sistema cotizador de seguro vehicular para la cobertura de accidentes y simulador de planes de contrato de seguros.</p><br><p>Este proyecto fue realmente una grata y satisfactoria experiencia, gracias al team DIGITAL. Los usuarios de pruebas para esta demo están ubicadas en la API pública de <a href='https://jsonplaceholder.typicode.com/users'>jsonplaceholder.</a></p>",
    "url": "https://webapp-rimac-csv.web.app/",
    "main_image": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-1.png?alt=media&token=5f703f18-20cc-4d6f-b927-4b5b8f022eae",
    "main_image_min": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-1-x50.png?alt=media&token=85836b14-cb45-4c28-97b6-88349d9a2840",
    "images": [
      {
        "name": "rimac-svr-1.png",
        "token_id": "5f703f18-20cc-4d6f-b927-4b5b8f022eae",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-1.png?alt=media&token=5f703f18-20cc-4d6f-b927-4b5b8f022eae"
      },
      {
        "name": "rimac-svr-2.png",
        "token_id": "9bea2ee3-cda2-4c70-9c05-e737d015260f",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-2.png?alt=media&token=9bea2ee3-cda2-4c70-9c05-e737d015260f"
      },
      {
        "name": "rimac-svr-3.png",
        "token_id": "54bf2de8-89db-4cdd-b020-6186144f1fc7",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-3.png?alt=media&token=54bf2de8-89db-4cdd-b020-6186144f1fc7"
      },
      {
        "name": "rimac-svr-4.png",
        "token_id": "0f04063a-cefb-45f5-b279-95f3c98f7edf",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-4.png?alt=media&token=0f04063a-cefb-45f5-b279-95f3c98f7edf"
      },
      {
        "name": "rimac-svr-5.png",
        "token_id": "96280e30-03d6-4bb3-ad20-6c7d58a4a416",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-5.png?alt=media&token=96280e30-03d6-4bb3-ad20-6c7d58a4a416"
      }
    ],
    "images_min": [
      {
        "name": "rimac-svr-1-x50.png",
        "token_id": "85836b14-cb45-4c28-97b6-88349d9a2840",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-1-x50.png?alt=media&token=85836b14-cb45-4c28-97b6-88349d9a2840"
      },
      {
        "name": "rimac-svr-2-x50.png",
        "token_id": "c2a49564-8347-4828-9119-edf87c194e0b",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-2-x50.png?alt=media&token=c2a49564-8347-4828-9119-edf87c194e0b"
      },
      {
        "name": "rimac-svr-3-x50.png",
        "token_id": "c7af56f3-a201-441f-a286-0019d7bc633b",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-3-x50.png?alt=media&token=c7af56f3-a201-441f-a286-0019d7bc633b"
      },
      {
        "name": "rimac-svr-4-x50.png",
        "token_id": "3c9c1338-5c97-4ef9-aece-759136a70a32",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-4-x50.png?alt=media&token=3c9c1338-5c97-4ef9-aece-759136a70a32"
      },
      {
        "name": "rimac-svr-5-x50.png",
        "token_id": "90fba1bc-823b-4bca-84dd-d009a7f7e73d",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Frimac-svr-5-x50.png?alt=media&token=90fba1bc-823b-4bca-84dd-d009a7f7e73d"
      }
    ],
    "position": "Frontend Developer",
    "client": {
      "name": "Rimac",
      "id": "6trf23ekj",
      "url": "http://rimac.com/",
      "address": "",
      "date_from": "06/06/2021",
      "date_to": "14/06/2021"
    }
  },
  "jota-shop": {
    "id": "jota-shop",
    "work_name": "Digitalización comercial",
    "description": `
      <p>
        Proyecto realizado como orientación a una digitalización comercial a la empresa jota-shop.
        Basado en la metodología SCRUM, el cual el contrato es libre sin restricción, quiere decir:
      </p>
      </br>
      <ul>
        <li><p>Contrato de 2 semanas, cada 2 semanas el cliente puede terminar el contrato si ve que el producto ya le es orgánico y de ganancia, o dinámica a su presupuesto. Tanto ambas parte pueden terminar el contrato.</p></li>
        <li><p>Ventajas agilidad en el proceso, mejoras en la afinidad del Stakeholder con el refinamiento. Seguimiento del producto.</p></li>
        <li><p>Mayor libertad al equipo de desarrollo ya que en el contrato solo va lo pactado en el Planing.</p></li>
        <li><p>Manejo de Bcklogs con priorización del Cliente, sabiendo que cada contrato se toma las historias que seleccionó en el Planing.</p></li>
        
      </ul>
      </br>
      <p>... Este proyecto está en curso, actualmente está en la primera versión funcional.</p>
      <p>... REACT, FIREBASE, GIT CI/CL, UI, UX</p>`,
      "url": "https://jota-shop.web.app/",
    "main_image": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fjota-shop-1.png?alt=media&token=689942fe-5c81-4758-b2ad-53fb85715150",
    "main_image_min": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fjota-shop-1-x50.png?alt=media&token=85561b56-c1c0-4e9a-a020-2d8dee2e5f3a",
    "images": [
      {
        "name": "jota-shop-1.png",
        "token_id": "689942fe-5c81-4758-b2ad-53fb85715150",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fjota-shop-1.png?alt=media&token=689942fe-5c81-4758-b2ad-53fb85715150"
      },
      {
        "name": "jota-shop-2.png",
        "token_id": "11e89eda-0215-4cf6-8c16-5f9c3de2d515",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fjota-shop-2.png?alt=media&token=11e89eda-0215-4cf6-8c16-5f9c3de2d515"
      }
    ],
    "images_min": [
      {
        "name": "jota-shop-1-x50.png",
        "token_id": "85561b56-c1c0-4e9a-a020-2d8dee2e5f3a",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fjota-shop-1-x50.png?alt=media&token=85561b56-c1c0-4e9a-a020-2d8dee2e5f3a"
      },
      {
        "name": "jota-shop-2-x50.png",
        "token_id": "1d8e5fbb-4d3d-44cd-b968-ebeadc3b0772",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fjota-shop-2-x50.png?alt=media&token=1d8e5fbb-4d3d-44cd-b968-ebeadc3b0772"
      }
    ],
    "position": "Ing. de Sistemas",
    "client": {
      "name": "Jota Shop",
      "id": "6trf23ekj2",
      "url": "http://jota-shop.com/",
      "address": "",
      "date_from": "14/10/2021",
      "date_to": "21/10/2021"
    }
  },
  "key-blog": {
    "id": "key-blog",
    "work_name": "KEY-BLOGS",
    "description": `
      <p>
        Proyecto Personal sobre blog técnico orientado a apasionados y profesionales, cuyo propósito es centralizar documentaciones técnicas (notas, manuales, fórmulas) de manera didáctica. El uso Actual es leer archivos de documentación *README.md*.
      </p>`,
    "url": "https://key-blogs-c4e1b.web.app/",
    "main_image": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fkey-blog-1.png?alt=media&token=7262b822-4f1f-4f9c-a234-7339cef1b3f3",
    "main_image_min": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fkey-blog-1-x50.png?alt=media&token=6006a3ba-a1ef-4cf6-84d5-9aabae37a8d2",
    "images": [
      {
        "name": "key-blog-1.png",
        "token_id": "7262b822-4f1f-4f9c-a234-7339cef1b3f3",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fkey-blog-1.png?alt=media&token=7262b822-4f1f-4f9c-a234-7339cef1b3f3"
      },
      {
        "name": "key-blog-2.png",
        "token_id": "4017ff9a-a286-4777-9bb6-3d57c9f51d13",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fkey-blog-2.png?alt=media&token=4017ff9a-a286-4777-9bb6-3d57c9f51d13"
      }
    ],
    "images_min": [
      {
        "name": "key-blog-1-x50.png",
        "token_id": "6006a3ba-a1ef-4cf6-84d5-9aabae37a8d2",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fkey-blog-1-x50.png?alt=media&token=6006a3ba-a1ef-4cf6-84d5-9aabae37a8d2"
      },
      {
        "name": "key-blog-2-x50.png",
        "token_id": "e7c920f0-5281-4982-ae41-2c5123fcd87a",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fkey-blog-2-x50.png?alt=media&token=e7c920f0-5281-4982-ae41-2c5123fcd87a"
      }
    ],
    "position": "Ing. de Sistemas",
    "client": {
      "name": "Personal",
      "id": "6trf23ekj2",
      "url": "http://jota-shop.com/",
      "address": "",
      "date_from": "14/07/2021",
      "date_to": "21/07/2021"
    }
  },
  "naru-sushi-bar": {
    "id": "naru-sushi-bar",
    "work_name": "Landing Page NARU",
    "description": `
      <p>
        Proyecto realizado para una empresa de venta de comida japonesa, sushi, makis, como concepto de CRM, retención de clientes y campañas publicitarias.
      </p>`,
    "url": "https://naru-landing.web.app/",
    "main_image": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-1.png?alt=media&token=60d20cbc-ce13-40bb-97a6-bfee2343b6eb",
    "main_image_min": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-1-x50.png?alt=media&token=68d7a8c0-513d-4b3a-ad51-14bd2044a915",
    "images": [
      {
        "name": "key-blog-1.png",
        "token_id": "7262b822-4f1f-4f9c-a234-7339cef1b3f3",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-1.png?alt=media&token=60d20cbc-ce13-40bb-97a6-bfee2343b6eb"
      },
      {
        "name": "key-blog-2.png",
        "token_id": "4017ff9a-a286-4777-9bb6-3d57c9f51d13",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-2.png?alt=media&token=05bdbe1a-f145-49d7-97c3-21a42875694c"
      },
      {
        "name": "key-blog-2.png",
        "token_id": "4017ff9a-a286-4777-9bb6-3d57c9f51d13",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-3.png?alt=media&token=7b7a18ac-3d6b-4071-92db-15594be3d968"
      }
    ],
    "images_min": [
      {
        "name": "naru-sushi-1-x50.png",
        "token_id": "68d7a8c0-513d-4b3a-ad51-14bd2044a915",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-1-x50.png?alt=media&token=68d7a8c0-513d-4b3a-ad51-14bd2044a915"
      },
      {
        "name": "naru-sushi-2-x50.png",
        "token_id": "d89a64f4-84e0-4adb-ae97-6b8f8bae3d9c",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-2-x50.png?alt=media&token=d89a64f4-84e0-4adb-ae97-6b8f8bae3d9c"
      },
      {
        "name": "naru-sushi-3-x50.png",
        "token_id": "48771ec0-754a-475b-9138-268874198f29",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fnaru-sushi-3-x50.png?alt=media&token=48771ec0-754a-475b-9138-268874198f29"
      }
    ],
    "position": "Ing. de Sistemas",
    "client": {
      "name": "Naru Sushi Bar",
      "id": "6trf23ekj2",
      "url": "http://jota-shop.com/",
      "address": "",
      "date_from": "14/07/2021",
      "date_to": "21/07/2021"
    }
  },
  "where-i-am": {
    "id": "where-i-am",
    "work_name": "Where I AM VUE",
    "description": `
      <p>
        Proyecto de curso de VUE.js como reto del profesor Fernando Herrera, utilizando la API de MAPBOX, buscando 5 coincidencias, calculando distancias y tiempo determinado.
      </p>`,
    "url": "https://where-am-i-mapbox.netlify.app/#/",
    "main_image": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-i-am-1.png?alt=media&token=e8eed3a0-84b9-4a4f-a116-fffc4d7dcaa8",
    "main_image_min": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-i-am-1-x50.png?alt=media&token=f11a987b-9fb1-421c-8e20-f8bf11adb05b",
    "images": [
      {
        "name": "where-i-am-1.png",
        "token_id": "e8eed3a0-84b9-4a4f-a116-fffc4d7dcaa8",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-i-am-1.png?alt=media&token=e8eed3a0-84b9-4a4f-a116-fffc4d7dcaa8"
      }
    ],
    "images_min": [
      {
        "name": "where-i-am-1-x50.png",
        "token_id": "f11a987b-9fb1-421c-8e20-f8bf11adb05b",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-i-am-1-x50.png?alt=media&token=f11a987b-9fb1-421c-8e20-f8bf11adb05b"
      }
    ],
    "position": "Frontend Developer",
    "client": {
      "name": "Personal",
      "id": "6trf23ekj2",
      "url": "http://jota-shop.com/",
      "address": "",
      "date_from": "14/07/2021",
      "date_to": "21/07/2021"
    }
  },
  "how-is-my-pokemon": {
    "id": "how-is-my-pokemon",
    "work_name": "DONDE ESTA MI POKEMON",
    "description": `
      <p>
        Proyecto de curso de VUE.js como reto del profesor Fernando Herrera, utilizando la API de pokemonAPI.
      </p>`,
    "url": "https://how-is-pokemon.netlify.app/",
    "main_image": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-is-my-pokemon.png?alt=media&token=454078f7-bdb5-4101-8564-1ef786a46048",
    "main_image_min": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-is-my-pokemon-x50.png?alt=media&token=2b17058a-d2da-4e5e-808a-36a76b57972d",
    "images": [
      {
        "name": "where-is-my-pokemon.png",
        "token_id": "454078f7-bdb5-4101-8564-1ef786a46048",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-is-my-pokemon.png?alt=media&token=454078f7-bdb5-4101-8564-1ef786a46048"
      }
    ],
    "images_min": [
      {
        "name": "where-is-my-pokemon-x50.png",
        "token_id": "2b17058a-d2da-4e5e-808a-36a76b57972d",
        "src": "https://firebasestorage.googleapis.com/v0/b/webapp-pedro-mendoza.appspot.com/o/trabajos%2Fwhere-is-my-pokemon-x50.png?alt=media&token=2b17058a-d2da-4e5e-808a-36a76b57972d"
      }
    ],
    "position": "Frontend Developer",
    "client": {
      "name": "Personal",
      "id": "6trf23ekj2",
      "url": "http://jota-shop.com/",
      "address": "",
      "date_from": "14/07/2021",
      "date_to": "21/07/2021"
    }
  },
}