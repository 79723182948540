import { ActionTree } from 'vuex';
import { WorksState } from './state';
import { StateInterface } from '@/store/index';
import { worksApi } from '@/apis';
import { WorksModel, SvrSeguroVehicular } from '@portal/toolbox/interfaces/works-interface';

const actions: ActionTree<WorksState, StateInterface> = {
  async getAllWorks( { commit }) {
    try {
      const resp = await worksApi.get<WorksModel>(`/all`);
      commit('setWorks', Object.values(resp.data));
    } catch (error) {
      // setLogAction('ERROR_GET_WORKS', 'device-x', '');
    }
  },
  dropWork({ commit }) {
    commit('setWork', null);
  },
  async getWorkById( { commit }, id) {
    try {
      commit('setWork', null);
      const { data } = await worksApi.get<SvrSeguroVehicular>(`/${id}`);
      commit('setWork', {
        role_description: data.position || '',
        client_description: data.client.name||'',
        project: {
          name: data.work_name||'',
          description: data.description||'',
          src: data.main_image||'',
          url: data.url||'#'
        },
      });
      return true;
    } catch (error) {
      commit('setWork', null);
    }
  }
}

export default actions;