import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import portalRouter from '@portal/router';

const routes: Array<RouteRecordRaw> = [
  {
    name: "About",
    ...portalRouter,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "@modules/portal/pages/about-page/AboutPage.vue"),
  // },
  { path: '/404', name: 'NotFound', component: import(/* webpackChunkName: "404" */ "@shared/pages/NoPageFound.vue") },
  { path: '/:pathMatch(.*)*', redirect: { name: 'NotFound' } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

trackRouter(router);

export default router;
