import { GetterTree } from 'vuex';
import { WorksState } from './state';
import { StateInterface } from '@/store/index';


const getters: GetterTree<WorksState, StateInterface> = {
  isWorkReady( state ) {
    return state.isLoading;
  }
}



export default getters;