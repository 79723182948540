import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag-next";

import './styles/main.sass';

let el = document.getElementById("prerender");
if (!!el) {
  el.style.display = 'none';
}

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    property: {
      id: "G-6YNVVY0C4N"
    }
  })
  .mount("#app");
