import { WorkFrontModel } from "@portal/toolbox/interfaces/works-interface";

export interface WorksState {
  dataList: [];
  dataOne?: WorkFrontModel | null;
  isLoading: boolean;
}

function state(): WorksState {
  return {
    dataList: [],
    dataOne: null,
    isLoading: false
  }
}

export default state;