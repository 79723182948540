import axios from 'axios';
import Env from '../env.json';
import MockAdapter from 'axios-mock-adapter';
import { sleep } from '@shared/toolbox/helpers/sleep.helper';
import { randomRange } from '@shared/toolbox/helpers/random.helper';
import { worksData } from './data';
/**
 * @author Pedro Jesus Mendoza Ardiles
 */
const worksApi = axios.create({
  baseURL: Env.VUE_BASE_MAP_URL_WORKS,
  params: {
    alternatives: false,
    access_token: 'pk.eyJ1IjoicG93ZXI1MDAwIiwiYSI6ImNsMzMzamlicjBkdGczZHJ3eWh6MDkydnEifQ.gaA5n9ttF0zoPWx6Y-o1ug'
  }
});

const worksApiMock = new MockAdapter(worksApi, { onNoMatch: "throwException" });

worksApiMock.onGet("/all").reply(function () {
  const timeout = randomRange(100, 350);
  const status  = 200;

  return sleep([ status, worksData ], timeout);
});

worksApiMock.onGet(/\/.*/gi).reply(function ({ url }) {
  const timeout = randomRange(25, 100);
  const status  = 200;

  return sleep([ status, 
    Object.values(worksData).find( ({ id }) => id === url?.replaceAll('/',''))
  ], timeout);
});

export default worksApi;
