import { createStore } from "vuex";

import worksModule from "@/modules/portal/store/works";
import { WorksState } from "@/modules/portal/store/works/state";
export interface StateInterface {
  works: WorksState
}

export default createStore({
  modules: {
    works: worksModule
  },
});
