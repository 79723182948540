import { MutationTree } from 'vuex';
import { WorksState } from './state';


const mutation: MutationTree<WorksState> = {
  setWorks( state, works ) {
    state.dataList = works;
    state.isLoading = true;
  },
  setWork( state, work ) {
    state.dataOne = work;
  },
}


export default mutation;